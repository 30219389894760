var gftt = gftt || {};

(function($){
	'use strict';

	gftt.colorPickerChoicesDynamicPosition = function( form_id ) {
		var $formWrapper = ( typeof form_id !== "undefined" ) ? $('#gform_wrapper_'+form_id) : $(".gform_wrapper");
		$formWrapper.find('.color-picker-choice .gftt-icon').each(function(){
			var $icon = $(this);
			var $choice = $icon.parent();
			var $label = $choice.find('> label');
			var offset = ( $label.width() - $choice.width() ).toString();
			$icon.css('transform', 'translateX(' + offset + 'px)');
		});
	};

	gftt.init = function( form_id, forced ){

		var $formWrapper = $('#gform_wrapper_'+form_id);

		/*
		var gfttHasInit = $formWrapper.data('gftt-init');
		if ( typeof gfttHasInit !== 'undefined' && gfttHasInit.toString() === 'true' && forced !== true ) {
			return;
		}
		*/

		$formWrapper.find('.gftt-content > span').each(function(){
			var $this = $(this);

			var $choice = $this.closest('.gchoice');
			if ( $choice.length ) {
				$choice.addClass('gform-theme__no-reset--children');
			}

			var tipID = $this.data('tid');
			var $tip = $('#'+tipID);
			var tipPlacement = $tip.data('placement');
			if (typeof tipPlacement === 'undefined' || tipPlacement === '') {
				tipPlacement = 'nw-alt';
			}
			$tip.data('powertiptarget', tipID+'-content');
			var userOptions = gform.applyFilters( 'gftt_tooltip_custom_options', {} );
			var mainOptions = {
				manual: false,
				followMouse: false,
				mouseOnToPopup: true,
				smartPlacement: true,
				popupClass: tipID,
				placement: tipPlacement
			};
			var options = $.extend( true, userOptions, mainOptions );
			$tip.powerTip(options);
			$tip.on({
				powerTipPreRender: function(){
					$('body').addClass('gftt_show');
				},
				powerTipRender: function(){
					var $self = $(this);
					$self.powerTip('reposition');
					$(document).trigger('gftt_tooltip_render', [this]);
				},
				powerTipOpen: function(){
					var $self = $(this);
					$self.data('tip-open', true);
					$(document).trigger('gftt_tooltip_open', [this]);
				},
				powerTipClose: function(){
					var $self = $(this);
					$self.data('tip-open', false);
					$('body').removeClass('gftt_show');
					$(document).trigger('gftt_tooltip_close', [this]);
				}
			});
		});

		$formWrapper.find('.gftt-label').each(function(){
			var $label = $(this);
			var $gfLabel = $label.closest('.gfield_label');
			$gfLabel.on('click', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
			});
			$label.on('click', function(e){
				var $container = $(this).closest('.gfield').find('.ginput_container');
				if ($container.length) {
					if ( $container.find('input:first').not(':radio').not(':checkbox').length ) {
						$container.find('input:first').not(':radio').not(':checkbox').focus();
					}
					else if ( $container.find('textarea:first').length ) {
						$container.find('textarea:first').focus();
					}
					else if ( $container.find('select:first').length ) {
						$container.find('select:first').focus();
					}
				}

			});
		});

		$formWrapper.find('.gftt-icon').on('click', function(e){
			e.preventDefault();
			e.stopImmediatePropagation();
			var $self = $(this);
			if ( $self.data('tip-open') === true ) {
				$self.powerTip('hide');
			}
			else {
				$self.powerTip('show');
			}
		});

		if ('ontouchstart' in document.documentElement) {
			// This is a touch-based device.
		}

		var dynamicPosition = gform.applyFilters('gftt_dynamic_colorpicker_choices_position', true, form_id);
		if ( dynamicPosition ) {
			$(window).on('resize', function(){
				gftt.colorPickerChoicesDynamicPosition( form_id );
			});
			setTimeout(function(){
				gftt.colorPickerChoicesDynamicPosition( form_id );
			}, 100);
		}

		$formWrapper.data('gftt-init', true);

	}

	$(document).on('gform_post_render', function(event, form_id, current_page){
		var globalDelay = ( window.hasOwnProperty('gf_tooltips_init_delay') ) ? parseInt( window.gf_tooltips_init_delay, 10 ) : 0;
		var delay = gform.applyFilters( 'gftt_tooltips_init_delay', globalDelay );
		if ( delay === 0 && $('#gform_' + form_id + ' .gpnf-nested-entries-container').length ) {
			// Seems we need to slightly delay the tooltip init if the form is using Gravity Wiz' Nested Forms and that field has a tooltip
			delay = 1;
		}
		if ( delay > 0 ) {
			setTimeout(function(){
				gftt.init( form_id );
			}, delay * 1000);
		}
		else {
			gftt.init( form_id );
		}
	});

})(jQuery);
